(function () {
    let ratio = 8906.6 / 650;

    function posCarrousel() {
        let ratioX = 8906.6 / $("#wrapper").width();
        let ratioY = 650 / $("#wrapper").height();
        $("#carrousel").css({
            top: 392 / ratioY,
            left: 4503 / ratioX,
            width: 147 / ratioX,
            height: 116 / ratioY,
        })
    }

    $("#wrapper").width(Math.min(650, $(window).height()) * ratio);
    posCarrousel();

    $(window).resize(function () {
        $("#wrapper").width(Math.min(650, $(window).height()) * ratio);
        posCarrousel();
    });

    $('a.menu').click(function (event) {
        event.preventDefault();

        var target = $(this.getAttribute('href'));
        if (target.length) {
            $('#menu').removeClass('expanded');
            $('html, body').stop().animate({
                scrollLeft: target.offset().left - 40
            }, 1000);
        }

        return false;
    });

    $('#menu .open').click(function () {
        $('#menu').addClass('expanded');
    });

    $('#menu .close').click(function () {
        $('#menu').removeClass('expanded');
    });

    function scrollHorizontally(e) {
        e.preventDefault();
        e = window.event || e;
        var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
        $(window).scrollLeft($(window).scrollLeft() - (delta * 60));
        return false;
    }
    if (window.addEventListener) {
        // IE9, Chrome, Safari, Opera
        window.addEventListener("mousewheel", scrollHorizontally, { passive: false });
        // Firefox
        window.addEventListener("DOMMouseScroll", scrollHorizontally, false);
    } else {
        // IE 6/7/8
        window.attachEvent("onmousewheel", scrollHorizontally);
    }

    window.addEventListener('scroll', function (e) {
        if ($(window).scrollLeft() < 300)
            $('#menu').hide();
        else
            $('#menu').show();
    });

    $('#carrousel .images').slick({
        arrows: false,
        dots: true
    });
})();